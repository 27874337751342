import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import HeroSimple from "../components/HeroSimple"
import Timeline9 from "../components/Timeline9"
import MainSimple from "../components/MainSimple"
import { useMagento2Query } from "../hooks/useMagento2Query"
import YearsInBusiness from "../components/YearsInBusiness"

const Magento2Page = () => {
  const data = useMagento2Query()
  const short = data.allSanityMagento2.edges[0].node
  return (
    <Layout>
      <SEO
        title="Magento 2 Migration Benefits"
      />
      <HeroSimple
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
      />
      <MainSimple
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainBtn={short.mainBtn}
        slug="/the-benefits-of-migrating-magento-1-to-magento-2/#contact"
      />
      <Timeline9
        heading={short.timelineHeading}
        bottomText={short._rawTimelineLastText}
        bottomBtn={short.timelineBtn}
        title1={short.timelineTitle1}
        title2={short.timelineTitle2}
        title3={short.timelineTitle3}
        title4={short.timelineTitle4}
        title5={short.timelineTitle5}
        title6={short.timelineTitle6}
        title7={short.timelineTitle7}
        title8={short.timelineTitle8}
        title9={short.timelineTitle9}
        text1={short._rawTimelineText1}
        text2={short._rawTimelineText2}
        text3={short._rawTimelineText3}
        text4={short._rawTimelineText4}
        text5={short._rawTimelineText5}
        text6={short._rawTimelineText6}
        text7={short._rawTimelineText7}
        text8={short._rawTimelineText8}
        text9={short._rawTimelineText9}
        icon1={short.timelineIcon1.asset.fluid.src}
        icon2={short.timelineIcon2.asset.fluid.src}
        icon3={short.timelineIcon3.asset.fluid.src}
        icon4={short.timelineIcon4.asset.fluid.src}
        icon5={short.timelineIcon5.asset.fluid.src}
        icon6={short.timelineIcon6.asset.fluid.src}
        icon7={short.timelineIcon7.asset.fluid.src}
        icon8={short.timelineIcon8.asset.fluid.src}
        icon9={short.timelineIcon9.asset.fluid.src}
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default Magento2Page
